import { ref, computed, reactive, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import axios from '@/services/axios'
import { IOrder } from '@/interfaces/order'

export default function useOrderRepositories() {
  const onMappingProduct = async (data: any) => {
    const { edit } = useResource(`/products/mapping/${data.productId}`)
    try {
      const res = await edit({
        variants: data.params,
        mappingData: data.mappingData,
      })
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }
  const onGroupMappingProduct = async (data: any) => {
    const { add } = useResource(`/products/group-mapping`)
    try {
      const res = await add({
        groupIds: data.groupIds,
        variants: data.params,
        mappingData: data.mappingData,
      })
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const onUploadDesignOrderItem = async (productId: string, data: any) => {
    const resource = `/products/${productId}/artworks`
    const { edit } = useResource(resource)
    try {
      const res = await edit(data)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const onSelectCatalogCodeOrderItem = async (productId: string, data: any) => {
    const resource = `/products/${productId}/catalog-code`
    const { edit } = useResource(resource)
    try {
      const res = await edit(data)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  return {
    onMappingProduct,
    onGroupMappingProduct,
    onUploadDesignOrderItem,
    onSelectCatalogCodeOrderItem,
  }
}
