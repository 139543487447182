
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
// import { Upload } from '@element-plus/icons'
import useUploadRepositories from '@/repositories/useUploadRepositories'

export default defineComponent({
  components: {},
  props: {
    index: {
      type: Number,
      default: 0,
    },
    indexCatalog: {
      type: Number,
      default: 0,
    },
    uploadName: {
      type: String,
      default: 'Upload',
    },
  },
  setup(props, { emit }) {
    const { onUploadImage } = useUploadRepositories()
    const state = reactive({
      isLoading: false,
    })

    const uploadDesignVariant = async (file: any, fileList: any) => {
      try {
        state.isLoading = true
        let res = await onUploadImage(file.raw)
        emit('onUploadFileDesign', {
          index: props?.index,
          indexCatalog: props?.indexCatalog,
          file: res,
        })
        state.isLoading = false
      } catch (error) {
        state.isLoading = false
      }
    }

    return { state, uploadDesignVariant }
  },
})
