
import {
  defineComponent,
  ref,
  computed,
  reactive,
  onMounted,
  toRefs,
  watch,
} from 'vue'
import useMappingReponsitories from '@/repositories/useMappingReponsitories'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import { imageUrl } from '@/utils/image'
import UploadDesign from './UploadDesign.vue'
import { ArrowDown, Check, Close } from '@element-plus/icons'
import { useNotification } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    UploadDesign,
    Check,
    Close,
    ArrowDown,
    SlotError,
  },
  props: {
    product: {
      type: Object,
    },
    catalogs: {
      type: Array,
    },
  },
  setup(props) {
    const { product } = toRefs(props)
    const { catalogs } = toRefs(props)
    const { success, error } = useNotification()

    const state = reactive({
      catalogTypeNumber: 1,
      tableData: ref<any[]>([]),
      multipleSelection: ref<any[]>([]),
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      optionsMapping: ref<any[]>([
        {
          name: 'Default option',
          des: 'Ex: T-Shirt, Hoodie, Mugs, ...',
          mapWith: '',
          value: 'styles',
          values: [
            {
              name: 'Default option',
              value: 'default',
            },
            {
              name: 'Size',
              value: 'size',
            },
            {
              name: 'Color',
              value: 'color',
            },
          ],
          optionValues: [] as any[],
        },
        {
          name: 'Color',
          des: 'Ex: Red, White, Green,...',
          mapWith: '',
          value: 'colors',
          values: [
            {
              name: 'Default option',
              value: 'default',
            },
            {
              name: 'Size',
              value: 'size',
            },
            {
              name: 'Color',
              value: 'color',
            },
          ],
          optionValues: [],
        },
        {
          name: 'Size',
          des: 'Ex: L, M, XL ...',
          mapWith: '',
          value: 'sizes',
          values: [
            {
              name: 'Default option',
              value: 'default',
            },
            {
              name: 'Size',
              value: 'size',
            },
            {
              name: 'Color',
              value: 'color',
            },
          ],
          optionValues: [],
        },
      ]),
      variantsMapping: ref<any[]>([]),
      mappingData: {
        colors: [],
        sizes: [],
        styles: [],
      },
      mapping: [],
      baseProduct: ref(''),
      color: ref(''),
      size: ref(''),
      productsMapping: ref<any[]>([]),
      chooseProduct: ref(''),
      product: ref(),
      options: ref<any[]>([]),
      catalog: ref(),
      showModalUploadDesign: false,
      selectedVariants: ref<any[]>([]),
      catalogDesigns: ref<any[]>([]),
      countWatcher: ref(0),
    })

    const stateTwo = reactive({
      catalogId: ref(),
    })

    const route = useRoute()

    const orderVariantTitle = route.query?.variantTitle
      ? route.query.variantTitle
      : null

    const { onMappingProduct } = useMappingReponsitories()
    const currentProduct = { ...product.value }

    const loadData = async () => {
      if (currentProduct) {
        if (
          currentProduct.mappingData &&
          currentProduct.mappingData.data &&
          currentProduct.mappingData.type === 'mappingOption'
        ) {
          state.optionsMapping = currentProduct.mappingData?.data?.mapping
          stateTwo.catalogId = currentProduct.mappingData?.data?.catalogId || ''
          await fetchCatalog()
          // if (state.catalog) {
          //   for (let index = 0; index < state.catalog.designs.length; index++) {
          //       state.catalogDesigns = [
          //         ...state.catalogDesigns,
          //         {
          //           designSrc: '',
          //           mockupSrc: '',
          //           position: '',
          //           name: '',
          //           width: '',
          //           height: '',
          //           template: '',
          //         },
          //       ]
          //     }
          //   }
          let variants = onMappingVariants()
          //console.log('variants', variants);

          state.variantsMapping = [...variants]
          state.tableData = [...variants]
        } else {
          // console.log('currentProduct.options', currentProduct.options)
          // console.log('state.optionsMapping', state.optionsMapping)
          let options = currentProduct.options
          let arrOptions = [] as any[]
          let arrOptionsAdd = [] as any[]
          if (options && options.length > 0) {
            for (let index = 0; index < options.length; index++) {
              const option = options[index]
              let op = {
                name: option.name,
                value: option.name.toLowerCase(),
              }
              if (state.optionsMapping.find(o => o.name === option.name))
                state.optionsMapping.find(o => o.name === option.name).mapWith =
                  option.name.toLowerCase()
              arrOptions = [...arrOptions, op]
            }

            // state.optionsMapping.forEach(element => {
            //   if(element.mapWith==='')
            //     element.mapWith = 'default'
            // })

            if (arrOptions.length < 3) {
              let de = {
                name: `Default option`,
                value: `default`,
              }
              arrOptionsAdd.push(de)
              // for (let index = 0; index < 3 - arrOptions.length; index++) {
              //   try {
              //     let de = {
              //       name: `Default option ${index + 1}`,
              //       value: `default-${index + 1}`,
              //     }
              //     arrOptionsAdd.push(de)
              //   } catch (error) {
              //     console.log(error)
              //   }
              // }
            }

            arrOptions = [...arrOptionsAdd, ...arrOptions]
            state.optionsMapping = state.optionsMapping.map(option => {
              if (option.mapWith === '') option.mapWith = 'default'
              return {
                ...option,
                values: arrOptions,
              }
            })
            //onMappingVariants('change')
          }
        }
      }
    }

    watch(stateTwo, (count, prevCount) => {
      fetchCatalog()
      state.countWatcher += 1
    })

    // watch(stateTwo, (count, prevCount) => {

    //   console.log('acb', count, prevCount);
    // if (catalogs.value) {
    //   let catalog = catalogs.value.find(
    //     (c: any) => c._id === state.catalogId
    //   ) as any
    //   if (catalog) {
    //     for (let index = 0; index < catalog.designs.length; index++) {
    //       state.catalogDesigns = [
    //         ...state.catalogDesigns,
    //         {
    //           designSrc: '',
    //           mockupSrc: '',
    //           position: '',
    //           name: '',
    //           width: '',
    //           height: '',
    //           template: '',
    //         },
    //       ]
    //     }
    //   }
    // }
    //})

    const optionsName = computed(() => {
      let options = currentProduct?.options || []
      let optionsName = options.map((op: any) => {
        return op.name
      })
      if (optionsName.length < 3) {
        for (let index = 0; index < 3 - optionsName.length; index++) {
          optionsName = [...optionsName, `Default option`]
        }
      }
      return optionsName
    })

    const onChangeMappingOption = (index: number) => {
      let options = currentProduct?.options || []
      let values =
        options.find(
          (o: any) =>
            o.name.toLowerCase() ===
            state.optionsMapping[index].mapWith.toLowerCase()
        )?.values || []

      const catalogOptions = state.catalog
        ? state.catalog[state.optionsMapping[index].value]
        : []

      if (values.length > 0) {
        let optionValues = values.map((v: any, i: number) => {
          const seletionCatalogOptions = catalogOptions?.find(
            (o: any) => o.name === v
          )
          return {
            selected: index === 0 && i === 0,
            option: v,
            optionSet: seletionCatalogOptions?.name,
          }
        })
        state.optionsMapping[index].optionValues = optionValues
        console.log(
          'state.optionsMapping values 1',
          values,
          state.optionsMapping
        )
      } else {
        state.optionsMapping[index].optionValues = [
          {
            selected: true,
            option: 'default',
            optionSet: '',
          },
        ]

        // console.log('state.optionsMapping values 2', state.optionsMapping)
      }
    }

    const fetchCatalog = async () => {
      const { getCatalog } = userCatalogRepositories()
      const { data } = await getCatalog(stateTwo.catalogId)
      state.catalog = data as any
      //Object.assign(stateTwo.catalog, data)
      if (state.catalog) {
        // console.log('state.countWather', state.countWatcher)

        if (
          stateTwo.catalogId !== currentProduct.mappingData?.data?.catalogId ||
          state.countWatcher > 1
        ) {
          if (state.variantsMapping && state.optionsMapping) {
            for (let index = 0; index < state.optionsMapping.length; index++) {
              onChangeMappingOption(index)
            }
          }
        }
      }
    }

    //watch(stateTwo, fetchCatalog)

    //watch(stateTwo.catalogId, fetchCatalog)

    // const catalog = computed(() => {
    //   if (props && catalogs.value && currentProduct) {

    //     let catalog = catalogs.value.find((o: any) => o._id === stateTwo.catalogId)
    //     console.log('catalog', catalog);

    //     return catalog
    //   }
    //   return null
    // })

    const onSelectMappingProduct = (option: any) => {
      state.optionsMapping[0].optionValues =
        state.optionsMapping[0].optionValues.map((op: any) => {
          return {
            ...op,
            selected: op.option === option.option,
          }
        })
    }

    const handleNodeClick = (data: any) => {
      console.log(data)
    }

    const onSelectVariants = (data: any) => {
      state.multipleSelection = data
    }

    const uploadArtwork = (data: any[]) => {
      const check = data.find((item: any) => {
        return (
          item.catalogs[0].catalogVariants[0].designQty !==
          data[0]?.catalogs[0].catalogVariants[0].designQty
        )
      })
      if (check) {
        error(`Design quantity different`)
      } else {
        let designs = []
        if (
          data[0].catalogs &&
          data[0].catalogs.length > 0 &&
          data[0].catalogs[0].catalogVariants &&
          data[0].catalogs[0].catalogVariants.length > 0 &&
          getVariant(data[0].catalogs[0].catalogVariants[0].code) &&
          state.catalog.variants.find(
            (s: any) =>
              s.catalogVariantCode ===
              data[0].catalogs[0].catalogVariants[0].code
          ) &&
          state.catalog.variants.find(
            (s: any) =>
              s.catalogVariantCode ===
              data[0].catalogs[0].catalogVariants[0].code
          ).designs.length > 0
        ) {
          designs = state.catalog.variants.find(
            (s: any) =>
              s.catalogVariantCode ===
              data[0].row.catalogs[0].catalogVariants[0].code
          ).designs
        } else {
          designs = state.catalog.designs.map((s: any) => {
            return s.name
          })
        }

        //const dsQty = data[0].catalogs[0].catalogVariants[0].designQty

        state.selectedVariants.length = 0
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          let findIndex = state.variantsMapping.findIndex(
            (v: any) => v.sku === element.sku
          )
          if (findIndex !== -1) {
            state.selectedVariants = [...state.selectedVariants, findIndex]
          }
        }
        state.catalogDesigns = []
        for (let i = 0; i < designs.length; i++) {
          let dsName = designs[i]
          let imgSrc = ''
          if (data.length === 1) {
            if (data[0].catalogs[0].designs.length > i)
              imgSrc = data[0].catalogs[0].designs[i].designSrc
          }
          console.log('imgSrc', data[0].catalogs[0])

          state.catalogDesigns = [
            ...state.catalogDesigns,
            {
              designSrc: imgSrc,
              mockupSrc: '',
              position: '',
              name: dsName,
              width: '',
              height: '',
              template: '',
            },
          ]
        }
        if (data) state.showModalUploadDesign = true
      }
    }

    const onSubmitMapping = async () => {
      try {
        let res = await onMappingProduct({
          productId: currentProduct?._id || '',
          params: state.variantsMapping,
          mappingData: {
            type: 'mappingOption',
            data: {
              mapping: state.optionsMapping,
              catalogId: stateTwo.catalogId,
            },
          },
        })
        success()
      } catch (er) {
        error()
      }
    }

    const onMappingVariants = (type = 'default') => {
      let variants = [] as any[]

      if (state.optionsMapping && state.optionsMapping.length > 0) {
        let options1 = {
          mapWith: state.optionsMapping[0].mapWith,
          name: state.optionsMapping[0].name,
          value: state.optionsMapping[0].value,
          values: state.optionsMapping[0].optionValues.filter(
            (v: any) => v.selected
          ),
        }
        let options2 = {
          mapWith: state.optionsMapping[1].mapWith,
          name: state.optionsMapping[1].name,
          value: state.optionsMapping[1].value,
          values: state.optionsMapping[1].optionValues.filter(
            (v: any) => v.selected
          ),
        }
        let options3 = {
          mapWith: state.optionsMapping[2].mapWith,
          name: state.optionsMapping[2].name,
          value: state.optionsMapping[2].value,
          values: state.optionsMapping[2].optionValues.filter(
            (v: any) => v.selected
          ),
        }

        let attributes = {} as any
        let attributesMap = {} as any
        if (options1.values.length > 0) {
          attributes[options1.mapWith.toLowerCase()] = options1.values.map(
            (v: any) => v.option
          )
          attributesMap[options1.name.toLowerCase()] = options1.values.map(
            (v: any) => v.optionSet
          )
        }

        if (options2.values.length > 0) {
          attributes[options2.mapWith.toLowerCase()] = options2.values.map(
            (v: any) => v.option
          )
          attributesMap[options2.name.toLowerCase()] = options2.values.map(
            (v: any) => v.optionSet
          )
        }
        if (options3.values.length > 0) {
          attributes[options3.mapWith.toLowerCase()] = options3.values.map(
            (v: any) => v.option
          )
          attributesMap[options3.name.toLowerCase()] = options3.values.map(
            (v: any) => v.optionSet
          )
        }

        let attrs = []
        let attrsMap = []

        for (const attr in attributes) {
          const values = attributes[attr]
          attrs.push(values.map((v: any) => ({ [attr]: v })))
        }
        attrs = attrs.reduce((a, b) =>
          a.flatMap((d: any) => b.map((e: any) => ({ ...d, ...e })))
        )
        for (const attr in attributesMap) {
          const values = attributesMap[attr]
          attrsMap.push(values.map((v: any) => ({ [attr]: v })))
        }
        attrsMap = attrsMap.reduce((a, b) =>
          a.flatMap((d: any) => b.map((e: any) => ({ ...d, ...e })))
        )

        if (currentProduct && state.catalog) {
          let options = currentProduct?.options || []
          // console.log('attrs-->', attrs)
          // console.log('options-->', options)

          // let catalog = catalogs.value.find(
          //   (c: any) => c._id === stateTwo.catalogId
          // ) as any
          for (let index = 0; index < attrs.length; index++) {
            const element = attrs[index]
            let elementMap = attrsMap[index]
            let variantCatalog = state.catalog
              ? state.catalog.variants.find(
                (v: any) =>
                  v.color.name === elementMap.color &&
                  v.size.code === elementMap.size
              )
              : null

            let variant
            if (options.length === 1) {
              variant = currentProduct.variants.find((v: any) => {
                return (
                  v.option1 &&
                  v.option1 === element[`${options[0].name.toLowerCase()}`]
                )
              })
              // console.log('element-->', element)
              // console.log('currentProduct.variants-->', currentProduct.variants)
              // console.log('variant-->', variant)
            }

            if (options.length === 2) {
              variant = currentProduct.variants.find((v: any) => {
                return (
                  v.option1 &&
                  v.option1 === element[`${options[0].name.toLowerCase()}`] &&
                  v.option2 &&
                  v.option2 === element[`${options[1].name.toLowerCase()}`]
                )
              })
            }

            if (options.length === 3) {
              variant = currentProduct.variants.find((v: any) => {
                return (
                  v.option1 &&
                  v.option1 === element[`${options[0].name.toLowerCase()}`] &&
                  v.option2 &&
                  v.option2 === element[`${options[1].name.toLowerCase()}`] &&
                  v.option3 &&
                  v.option3 === element[`${options[2].name.toLowerCase()}`]
                )
              })
            }

            if (variant) {
              if (variantCatalog) {
                if (
                  (variant.catalogs && variant.catalogs.length === 0) ||
                  type !== 'default'
                ) {
                  let mappingVariantCode = state.catalog.variants.find(
                    (k: any) =>
                      k.catalogVariantCode ===
                      variantCatalog?.catalogVariantCode
                  )
                  let catalogs = [
                    {
                      catalogId: state.catalog?._id || '',
                      catalogName: state.catalog?.name || '',
                      catalogProductCode: state.catalog?.productCode || '',
                      catalogVariants: [
                        {
                          _id: variantCatalog?._id || '',
                          name: `${variantCatalog?.name || ''}`,
                          code: `${variantCatalog?.catalogVariantCode || ''}`,
                          color: variantCatalog?.color,
                          size: variantCatalog?.size,
                          value: '',
                          productOption: '',
                          designQty:
                            mappingVariantCode &&
                              mappingVariantCode.designs.length > 0
                              ? mappingVariantCode.designs.length
                              : state.catalog.designs.length,
                        },
                      ] as any[],
                      designs: variant?.catalogs[0]?.designs || ([] as any[]),
                      quantity: 1,
                    },
                  ]
                  // console.log('state.catalog.designs.length', catalogs)
                  variant.catalogs = [...catalogs]
                }
              }

              variants = [...variants, variant]
            }
          }
        }
      }

      return variants
    }

    const onChangeOption = async () => {
      let variants = onMappingVariants('change')
      state.variantsMapping = variants
      state.tableData = variants
    }

    const getVariant = (code: any) => {
      if (state.catalog) {
        let variant = state.catalog
          ? state.catalog.variants.find(
            (v: any) => v.catalogVariantCode === code
          )
          : null

        if (variant) {
          return variant
        }
      }
      return null
    }

    const onUploadFileDesign = (data: any) => {
      state.catalogDesigns[data.index].designSrc = data.file.path
      for (let index = 0; index < state.selectedVariants.length; index++) {
        const variantIndex = state.selectedVariants[index]
        state.variantsMapping[variantIndex].catalogs[data.indexCatalog].designs[
          data.index
        ] = {
          designSrc: data.file.path,
          mockupSrc: '',
          position: '',
          name: '',
          width: '',
          height: '',
          template: '',
        }
      }
    }

    const onSubmitDesign = () => {
      if (currentProduct && currentProduct.mappingData) {
        onSubmitMapping()
      }
      state.showModalUploadDesign = false
    }

    loadData()

    return {
      imageUrl,
      state,
      stateTwo,
      optionsName,
      // catalog,
      handleNodeClick,
      onSelectVariants,
      uploadArtwork,
      onChangeMappingOption,
      onSelectMappingProduct,
      onChangeOption,
      onSubmitMapping,
      getVariant,
      onUploadFileDesign,
      onMappingVariants,
      onSubmitDesign,
      orderVariantTitle,
      route,
    }
  },
})
